<template>
  <div class="g_page_box">
    <div class="g_main_content">
      <g-loading :loading="loading" />
      <div class="out">
        <div class="list" v-for="(item, idx) in data" :key="idx">
          <div class="left">
            <div class="title" @click="set_default(item)">
              <span>{{ item.address + item.house_num }}</span>
              <div class="default" v-if="item.default_address">默认</div>
            </div>
            <div class="sub">{{ item.recipient }} {{ item.phone }}</div>
          </div>
          <div class="right">
            <div class="btn" @click.stop="get_default(item)">
              <span>修改</span>
              <van-icon name="arrow" />
            </div>
          </div>
        </div>
      </div>
      <router-link :to="{path: '/edit-location', query: {...$route.query}}">
        <div class="bottom" >新增收件地址</div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { address_list, set_default_location } from "./service";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      data: [],
    };
  },
  computed: {},
  components: {},
  created() {
    this.clear_state()
    this.get_address();
  },
  methods: {
    ...mapMutations("locationData", ["set_location"]),
    ...mapMutations("locationData", ["clear_state"]),
    async get_address() {
      this.loading = true;
      try {
        const { data } = await address_list();
        this.data = data;
      } finally {
        this.loading = false;
      }
    },
    get_default(item) {
      this.set_location(item);
      this.$router.push({ path: "/edit-location", query: {...this.$route.query} });
    },
    async set_default(item){
      item.default_address = true
      this.loading = true
      try {
        await set_default_location(item.id)
        this.$router.push({
          path: '/electronic-rescription',
          query: {...this.$route.query}
        })
      }finally {
        this.loading = false
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.g_page_box {
  background: #f2f7f8;
  padding-bottom: 75px;
}

.out {
  background: #fff;
}

.list {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  border-bottom: solid 1px rgba(221, 221, 221);
  padding: 15px 16px;
}

.left {
  width: 75%;
}

.title {
  font-size: 14px;
  line-height: 20px;
  color: #292929;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;

  & span {
    flex: 1;
  }
}

.default {
  width: 39px;
  height: 17px;
  background: #01e9ae;
  border-radius: 10px;
  line-height: 17px;
  text-align: center;
  color: #fff;
  font-size: 12px;
}

.sub {
  font-size: 12px;
  color: #919191;
  line-height: 20px;
}

.btn {
  font-size: 14px;
  line-height: 18px;
  color: #969799;
  display: flex;
  align-items: center;
}

.bottom {
  width: calc(100% - 32px);
  position: fixed;
  bottom: 39px;
  height: 36px;
  background: #1890ff;
  border-radius: 18px;
  font-size: 14px;
  line-height: 36px;
  color: #ffffff;
  text-align: center;
  left: 16px;
}
</style>
