import api from '../../utils/api'

// 获取
export async function address_list (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         items: [
  //           {
  //             recipient: '李明',
  //             phone:1354050607,
  //             address: '上海市徐汇区保利时光',
  //             house_num: 'B座6楼',
  //             default_address: true
  //           },
  //           {
  //             recipient: '李小明',
  //             phone:13540543567,
  //             address: '上海市徐汇区',
  //             house_num: 'B座7楼',
  //             default_address: false
  //           },
  //         ],
  //       }
  //     })
  //   }, 500)
  // })
  return api.get('/api/v1/mp/address/list', payload)
}

// 添加
export async function set_default_location (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: null
  //     })
  //   }, 500)
  // })
  return api.post(`/api/v1/mp/address/default/${payload}` )
}
